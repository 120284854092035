.theme-wysiwyg, .section-wysiwyg {

  h1 {
    font-size: $h2-fs;
    margin-bottom: 30px;
  }

  h2 {
    font-size: $h3-fs;
    font-family: $ff-accent;
    line-height: 1.4;
    margin-bottom: 30px;
  }

  h3 {
    font-size: $h4-fs;
    font-family: $ff-accent;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
  }

  h4 {
    font-size: $h5-fs;
    font-weight: 700;
    margin-bottom: 0;
    font-family: $ff-accent;

    & + * {
      margin-top: 15px;
    }
  }


  h5 {
    font-size: $base-fs;
    font-weight: 700;
    font-family: $ff-accent;
  }


  h2 + a.cta {
    margin-top: 0!important;
  }


  h3, h4 {
    & + a.cta {
      margin-top: 15px!important;
    }
  }


  a.cta {
    margin: 30px 0 0;

    & + * {
      margin-top: 15px;
    }
  }


  p + h2,
  p + h3,
  p + h4,
  p + h5 {
    margin-top: 30px;
  }


  p + p > a.cta {
    margin-top: 15px!important;
  }


  p + ul, p + ol {
    margin-top: 15px!important;
  }


  ol + h2,
  ol + h3,
  ol + h4,
  ol + h5,

  ul + h2,
  ul + h3,
  ul + h4,
  ul + h5 {
    margin-top: 30px;
  }


  ul, ol {
    margin: 15px 0 0;

    & + * {
      margin-top: 15px;
    }

    li {
      & + li {
        margin-top: 8px;
      }


      & > ol, & > ul {
        margin: 8px 0;
      }

    }
  }



  ol {
    padding: 0;
    list-style: none;
    list-style-position: inside;
    counter-reset: a;

    li {
      padding-left: 30px;
      position: relative;
      counter-increment: a;

      &:before {
        content: counter(a) ". ";
        position: absolute;
        left: 0;
        color: $c-primary;
        font-weight: 700;
      }
    }


    li > ol {
      counter-reset: inner;

      li {
        padding-left: 15px;
        position: relative;
        counter-increment: inner;

        &:before {
          content: counter(inner, lower-latin)".";
          position: absolute;
          left: 0;
          color: $c-primary;
          font-weight: 700;
        }
      }
    }

  }//end ol


  ul {
    list-style: none;
    padding: 0;
    text-align: left;

    li {

      position: relative;
      padding-left: 21px;

      & + li {
        margin-top: 8px;
      }

      &:before {
        content: '\2022';
        color: $c-primary;
        margin: 0;
        position: absolute;
        top: 5px;
        left: 0;
        font-weight: 700;
        font-size: 15px;
        line-height: 1;
      }

    }//end li

  }//end ul


  p, li {
    a:not(.cta) {
      color: $black;
      text-decoration: underline;
      text-decoration-color: $black;

      @include transition(all .3s ease-in-out);

      &:hover {
        text-decoration-color: $c-primary;
      }
    }
  }





  img {
    max-width: 100%;
  }


  strong {
    font-size: inherit;
    font-family: inherit;
  }


  .embed-responsive.embed-responsive-16by9 {
    & + h2, & + h3, & + h4, & + p, & + ul {
      margin-top: 30px;
    }
  }



  hr {
    margin-top: 15px;
    margin-bottom: 21px;
  }





























}
