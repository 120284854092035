/*Header structure*/
header.header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
  background: $light-blue;
  @include box-shadow-default;

  &.sticky {
    position: fixed;
  }

  @media(max-width: 991px) {
    padding: 8px 0;
  }

  p.header-logo-text {
    font-size: $h4-fs;
    font-weight: 700;
  }

  //Remove max-width from boxed layout container for tab
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    div.container {
      max-width: none;
    }
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    div.container {
      max-width: none;
    }
  }


}//end header


a.navbar-brand {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-width: 180px;
    min-width: 90px;
  }
}


a.mobile-book-btn {
  font-size: $fs-xxs;
  font-weight: 700;
  padding: 5px 9px;
  margin: 0 auto;
  min-width: 0;
  border: solid 1px $white;
  background: $white!important;
  color: $light-blue;
  @include border-radius(30px);
  font-family: $ff-heading;

  &:hover, &:focus, &:active {
    background: $blue!important;
    color: $white;
    border-color: $white!important;
  }
}


//Mobile booking dropdown
div.mobile-booking-multi {

  ul.mobile-book-ddm {

    display: block;
    list-style: none;
    position: relative;
    padding: 0;
    margin: 0;

    li {
      display: block;
    }


    li.book {
      @extend .cta;
      font-size: $fs-sm;
      padding: 5px 20px 4px 8px;
      margin: 0 auto;
      min-width: 0;
      font-weight: 400;
      display: block;

      span.inner {
        display: block;
        width: 100%;
      }

      span.icon-wrap {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        font-size: 10px;
      }

      @media(max-width: 991px) {
        padding: 5px 25px 4px 15px;

        span.icon-wrap {
          right: 8px;
        }
      }


      @media(max-width: 767px) {
        padding: 5px 20px 4px 8px;

        span.icon-wrap {
          right: 5px;
        }
      }


      &.active {
        & + li.mobile-ddm {
          max-height: 500px;
        }
      }


    }//end book


    li.mobile-ddm {
      overflow: hidden;
      max-height: 0;
      @include transition(all $trans);
      position: absolute;
      top: 100%;
      left: 0;
      background: $c-primary;
      @include box-shadow-default;
      width: calc(100vw - 30px);
      max-width: 320px;
      z-index: 1;

      ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: block;

          a {
            display: block;
            padding: 8px 15px;
            color: $white;
            background: $c-primary;
            @include transition(background $trans);

            &:hover {
              background: $c-primary-hover;
            }
          }
        }
      }
    }
  }//end mobile-book-ddm



}//end .mobile-booking-multi


//logo_left_nav_centered
body.logo_left_nav_centered {

  #navbarCollapse {
    justify-content: center;
  }

  div.mobile-booking-multi ul.mobile-book-ddm li.mobile-ddm {
    left: initial;
    right: 0;
  }

  @media(max-width: 991px) {
    .hamburger {
      margin: 0 auto;
    }

    a.navbar-brand {
      img {
        max-width: 64px;
      }
    }

    a.mobile-book-btn {
      margin: 0 0 0 auto;
    }
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    a.navbar-brand {
      img {
        max-width: 90px;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  	a.navbar-brand {
      img {
        min-width: 90px;
      }
    }
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    a.navbar-brand {
      img {
        min-width: 90px;
      }
    }
  }

}//end logo_left_nav_centered


body.logo_left_nav_right {

  @media(max-width: 991px) {
    a.navbar-brand {
      img {
        max-width: 90px;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  	a.navbar-brand {
      img {
        min-width: 90px;
      }
    }
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    a.navbar-brand {
      img {
        min-width: 90px;
      }
    }
  }

  div.mobile-booking-multi ul.mobile-book-ddm li.mobile-ddm {
    position: fixed;
    width: calc(100% - 30px);
    left: 15px;
    right: 0;
    top: 61px;
    z-index: 1;
    max-width: none;
  }

}//end logo_left_nav_right


body.header-logo-top {

  #navbarCollapse {
    justify-content: center;
  }

  a.navbar-brand {

    padding-top: 10px;
    display: inline-block;

    @media(max-width: 991px) {
      padding-top: 0;
    }

    img {
      max-width: 270px;

      @media(max-width: 991px) {
        max-width: 150px;
      }

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        max-width: 180px;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        max-width: 210px;
      }
    }
  }

  @media(max-width: 991px) {
    a.mobile-book-btn {
      margin: 0 auto 0 0;
    }
  }

  @media(max-width: 767px) {
    a.mobile-book-btn {
      font-size: 14px;
    }
  }
}


body.contact-bar {
  @media(max-width: 991px) {
    header {
      padding-top: 0;
    }
  }
}


//WPML lang switcher styles
header.header {
  nav.navbar {


  div.autonomy-lang-switcher {

    div.wpml-ls-legacy-dropdown-click {
      width: auto;
      margin-left: 15px;

      a {
        background: transparent;
      }

      a:hover, a:focus {
        background: $c-primary;
        color: $white;
      }

      a.wpml-ls-item-toggle {
        padding-right: 30px;
        border: none;

        &:after {
          top: calc(50% - 4px);
        }
      }

      .wpml-ls-flag {
        display: inline-block;
        vertical-align: inherit;
      }

      .wpml-ls-sub-menu {
        text-align: left;
        background: $white;
      }

    }

    //end wpml-ls-legacy-dropdown-click


    @media(max-width: 991px) {
      display: block;
      width: 100%;
      text-align: center;

      div.wpml-ls-legacy-dropdown-click {
        width: 60px;
        margin: 0 auto;
      }
    }

  }

  //end autonomy-lang-switcher
}//end nav.navbar
}//end header























