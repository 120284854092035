blockquote {
  background: transparent;
  border: none;
  margin: 30px 60px 30px 0;
  padding: 0;
  quotes: "\201C""\201D""\2018""\2019";
  position: relative;
  padding-left: 45px;

  @media(max-width: 767px) {
    padding: 0 35px;
    padding-right: 0;
    margin-right: 0;
  }
}

blockquote:before {
  color: $black;
  content: '\201C';
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
  font-family: 'Times new roman', serif;
  position: absolute;
  left: 10px;
  top: 15px;

  @media(max-width: 767px) {
    position: absolute;
    top: 15px;
    left: 0;
  }
}

blockquote p {
  display: inline;
  font-size: $h5-fs;
  font-weight: bold;
}
