
div.wpcf7 {

  max-width: 650px;
  margin: 0 auto;

  span.wpcf7-not-valid-tip {
    font-size: $fs-sm;
  }

  //Response styling
  .wpcf7-response-output {

    &.wpcf7-mail-sent-ok {
      background: rgba(56, 56, 56, .5);
      border: solid 1px $white;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      font-size: $fs-xs;
      font-family: $ff-accent;
      padding: 5px;
    }

    &.wpcf7-mail-sent-ng {
      background: rgba(255, 0, 0, 0.5);
      font-weight: bold;
    }

  }

  span.ajax-loader {
    background-image: url('/wp-content/themes/Autonomy/dist/images/icons/loading.gif');
    width: 24px;
    height: 24px;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px;
    margin: -11px 0 0;
    padding: 0;
    position: absolute;
    top: 50%;
    right: -30px;

    @media(max-width: 767px) {
      right: 15px;
      z-index: 5;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      right: 15px;
      z-index: 5;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      right: -30px;
    }
  }


  label {
    width: 100%;
  }

  label + label {
    margin-top: 15px;
  }


  label.fname, label.sname {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-top: 0;
  }

  label.fname {
    padding-right: 8px;
  }

  label.sname {
    padding-left: 7px;
  }


  textarea {
    height: 120px;
  }


  div.submit-wrap {
    text-align: center;
    position: relative;
    margin-top: 15px;

    input[type="submit"] {
      margin: 0;
    }


    div.inner {
      display: inline-block;
      position: relative;
    }

    span.wpcf7-spinner {
      position: absolute;
      top: 50%;
      right: -30px;
      margin: 0;
      @include prefix(transform, translateY(-50%));
    }

  }


}

//end wpcf7
