section.map-section {

  .theme-map {

    width: 100%;
    height: 100%;
    min-height: 50vh;

  }//end .theme-map


  &.map-section-not-boxed {
    padding-bottom: 0;

    div.map-outer {
      padding: 0;
    }
  }


  //Map buttons
  a.cta.mobile-map-toggle {
    position: relative;

    span.icon-wrap {
      position: absolute;
      right: 15px;
      top: 50%;
      @include transform(translateY(-50%));
    }
  }

  ul.map-buttons-row {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    @media(max-width: 767px) {
      display: block;
      overflow: hidden;
      max-height: 0;
      @include transition(all $trans);

      &.active {
        max-height: 650px;
      }
    }

    li {
      flex: 1;
      flex-wrap: wrap;

      @media(max-width: 767px) {
        display: block;
        width: 100%;
        margin-top: 1px;
      }

      & + li {
        margin-left: 1px;

        @media(max-width: 767px) {
          margin-left: 0;
        }
      }
    }


    button.map-button {
      border: none;
      font-size: $base-fs;
      width: 100%;
      max-width: none;
      font-weight: 400;
      min-width: 0;
      height: 100%;

      @media(max-width: 991px) {
        font-size: $fs-sm;
      }
    }
  }




}
