

//Nav layout
@media (min-width: 992px) {
  nav.navbar {

    &.layout-nav-right {
      ul[id^=menu-main-menu] {
        margin: 0 0 0 auto;
      }//ul#menu-main-menu
    }//end layout-nav-right

  }//nav.navbar
}

nav.navbar {
  padding: 15px 0;
}

ul[id^=menu-main-menu] {
  margin: 0;
  padding: 0;
  align-items: center;

  li {

    & > a {
      text-transform: uppercase;
      color: $off-white;
    }


    a {
      font-size: $fs-xxs;
      position: relative;
      font-family: $ff-heading;
      font-weight: 700;
    }//end menu a


    &:not(.menu-item-has-children) {

      &.current-menu-item {
        a {
          &:before {
            left: 0;
          }
        }
      }

      a {
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          @include transition(all .185s ease-in-out);
          bottom: 0;
          left: -100%;
          background: $off-white!important;
        }

        &:hover {
          &:before {
            left: 0;
          }
        }
      }
    }//not has children


    &.book {
      a {
        background: $off-white;
        color: $light-blue;
        @include transition(all $trans);
        border: solid 1px $white;
        padding: .5rem 15px 0.4rem!important;
        @include border-radius(30px);

        &:hover {
          background: $blue;
          color: $white;
        }

        &:before {
          display: none;
        }
      }
    }


  }//end li


  & > li {
    & + li {
      margin-left: 10px;
    }
  }


  //Has children
  li.menu-item-has-children.dropdown {
    @include transition(background $trans);

    & > a {
      padding-right: 27px;

      &:after {
        content: "\f123";
        display: inline-block;
        font-family: Ionicons;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        right: 10px;
        @include prefix(transform, translateY(-50%));
        font-size: 10px;
      }

      @media(max-width: 991px) {
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }

    a {
      @include transition(color $trans);
    }

    &:hover {
      background: $c-primary;

      a {
        color: $white;
      }

      ul.dropdown-menu {
        visibility: visible;
        @include opacity(100, 1);
        z-index: 1;
      }
    }

    &.current-menu-item {
      background: $c-primary;

      a {
        color: $white!important;
      }
    }


    &.ddm-right {
      ul.dropdown-menu {
        right: 0!important;
        left: initial!important;
      }

    }

  }//List item has children





}//end menu-main-menu


//Adjust spacing for different header layouts
body.logo_left_nav_right, body.logo_left_nav_centered {

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  	ul[id^=menu-main-menu] {

      li {
        & + li {
          margin-left: 5px;
        }

        &:not(.menu-item-has-children) {
          a.nav-link {
            padding-left: 7px;
            padding-right: 7px;
          }
        }

        &.menu-item-has-children {
          a.nav-link {
            padding-left: 7px;
            padding-right: 15px;

            &:after {
              right: 4px;
              font-size: 8px;
            }
          }
        }


      }

    }//end ul[id^=menu-main-menu]
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    ul[id^=menu-main-menu] {

      li {
        & + li {
          margin-left: 5px;
        }

        &:not(.menu-item-has-children) {
          a.nav-link {
            padding-left: 7px;
            padding-right: 7px;
          }
        }

        &.menu-item-has-children {
          a.nav-link {
            padding-left: 7px;
            padding-right: 15px;

            &:after {
              right: 4px;
              font-size: 8px;
            }
          }
        }


      }

    }//end ul[id^=menu-main-menu]
  }

}//end logo_left_nav_right


body.header-logo-top {

  @media(min-width: 992px) {

    div.nav-container-outer {
      background: $nav_bar_blue;
      margin-top: 10px;
    }

    nav.navbar.layout-nav-centered {
      padding: 10px 0 10px;
    }

  }//end desktop

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    ul[id^=menu-main-menu]>li+li {
      margin-left: 10px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    ul[id^=menu-main-menu]>li+li {
      margin-left: 10px;
    }
  }

}




//Drop down menu
ul.dropdown-menu {
  background: $c-primary;
  border-radius: 0;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 0;
  border: none;
  visibility: hidden;
  @include opacity(0, 0);
  z-index: -1;
  @include transition(opacity $trans);
  display: block;



  a.dropdown-item {
    display: block;
    float: none;
    text-transform: none!important;
    padding: 8px 15px;
    color: $white;
    @include transition(background $trans);

    &:hover, &:active {
      background: $c-primary-hover;
    }

    @media(max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

}//end dropdown-menu


nav.navbar.navbar-toggleable-sm.navbar-expand-lg {

  @media(max-width: 991px) {

    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    margin: 0;
    background: $blue;
    @include transition(left $trans);
    border-top: solid 1px $light-blue!important;
    padding: 15px;
    @include box-shadow-default;

    &.active {
      left: 0;
    }


    div.navbar-collapse.collapse {
      display: block!important;
    }


    ul[id^=menu-main-menu] {

      text-align: center;

      li {

        &.book {
          display: none;
        }

        & + li {
          margin-left: 0;
        }

        a {
          display: inline-block;
          width: auto;
        }

      }

    }//end ul[id^=menu-main-menu]

    ul[id^=menu-main-menu] li.menu-item-has-children {
      a {
        color: $black;
      }
    }

    ul[id^=menu-main-menu] li.menu-item-has-children.dropdown:hover {
      background: transparent;
    }

    ul.dropdown-menu {

      text-align: center;
      visibility: visible;
      @include opacity(100, 1);
      z-index: 1;
      background: transparent;

      a {
        color: $black;
      }

      a.dropdown-item {
        font-weight: 400;
        padding-top: 0;
        padding-bottom: 0;
      }
    }//end ddm


  }

}//end mobile navmenu



















//Mobile nav menu

.hamburger {
  padding: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  display: none;
  margin-left: auto;
  width: 30px;
  z-index: 10;
  position: relative;

  @media(max-width: 991px) {
    display: block;
  }
}

.hamburger-box {
  width: 30px;
  height: 24px;
  display: block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: $c-primary;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}








































