section.carousel-section {

  .owl-stage {
    display: flex;
  }

  .owl-item {
    display: flex;
    flex: 1 0 auto;
    padding: 4px;

    @media(max-width: 767px) {
      padding: 1px;
    }
  }

  div.section-carousel-item {

    @include box-shadow-default;
    @include content-card;

  }//end grid item


}//end carousel-section
