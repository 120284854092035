
.cta {
  display: inline-block;
  width: auto;
  text-align: center;
  min-width: 300px;
  padding: 15px 30px;
  background: $light-blue!important;
  color: $white;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none!important;
  overflow: hidden;
  z-index: 2;
  @include border-radius(30px);

  @include transition(all .3s ease-in-out);


  @media(max-width: 767px) {
    min-width: 0;
    padding: 8px 30px;
  }

  &:hover {
    background: $blue!important;
    border-color: $blue!important;
    color: $white;
  }


  &.sm {
    padding: 5px 30px;
    font-weight: 400;
    font-size: $fs-sm;
    min-width: 0;
  }

  &.md {
    padding: 8px 30px;
    font-weight: 500;
    min-width: 120px;
  }

}//end cta






























