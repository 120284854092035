
section.gallery-section {

  overflow: hidden;

  &.full {
    div.gallery-wrap {
      margin: 0 -15px;
    }

    &.grid {
      padding-bottom: 0;
    }
  }


  &.no-padding-top {
    padding-top: 0;
  }


  //grid
  div.gallery-wrap {
    ul {

      margin: 0;
      padding: 0;
      list-style: none;


      li {
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding: 5px;
      }

      @media(min-width: 992px) {
        &.items-4 {
          li {
            width: calc(100% / 4);
          }
        }

        &.items-5 {
          li {
            width: calc(100% / 5);
          }
        }

        &.items-6 {
          li {
            width: calc(100% / 6);
          }
        }
      }


      @media(max-width: 991px) {
        li {
          width: 33.333%;
        }
      }


    }
  }//end gallery-wrap {


  a.mfp-image-anchor {

    display: block;
    position: relative;

    &:after {
      content: '';
      position: relative;
      display: block;
      padding-bottom: 100%;
    }


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }


    span.icon-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($c-primary, 0.85);
      color: $white;
      z-index: 1;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include transition(opacity $trans);
      @include opacity(0, 0);

      i {
        font-size: 24px;
        position: relative;
        @include transition(all $trans);
        top: 60px;
        @include opacity(0, 0);
      }
    }


    &:hover, &:active {
      span.icon-wrap {
        @include opacity(100, 1);

        i {
          top: 0;
          @include opacity(100, 1);
        }
      }
    }


  }//end a.mfp-image-anchor


}//end section.gallery-section
