
body.blog, body.archive, body.search {
  background: $off-white;

  section.blog-hero {

    padding: 45px 0;

    h1 {
      font-size: $h2-fs;
      margin: 0;
    }

    @media(max-width: 991px) {
      padding: 30px 0;
    }

  }//end blog-hero
}//end body.blog



section#blog-landing {
  background: $off-white;
  padding-top: 0;
}


article.blog {
  min-height: 200px;
  margin-bottom: 30px;
  overflow: hidden;
  background: $white;
  border: solid 1px $grey;
}

article.blog figure {
  height: 350px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;

  a {
    display: block;
    position: relative;
    height: 100%;

    &:after {
      content: '';
      position: relative;
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media(max-width: 767px) {
    height: 210px;
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    height: 240px;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 320px;
  }
}


article.blog .post_info {
  padding: 15px 30px;
  position: relative;
  box-sizing: border-box;
  height: 100%;

  @media(max-width: 767px) {
    padding: 30px 15px;
  }
}

article.blog .post_info small {
  font-weight: 500;
  color: $dark-grey;
}


/////////

article.blog {

  ul.theme-post-meta {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  small {
    font-weight: normal!important;
    color: $dark-grey!important;

    li.post-category {
      display: none;

      i {
        display: none;
      }
    }

    li.post-date {
      font-weight: normal!important;
      color: $dark-grey!important;
      font-size: $fs-xs;
      margin-bottom: 5px;
      i {
        display: none;
      }
    }

    li.post-author {
      display: none;

      i {
        display: none;
      }
    }
  }

  h3 {
    font-size: $h4-fs;
    font-weight: bold;
    text-transform: uppercase;

    a {
      color: $black;
    }
  }

  p.post-excerpt {
    margin-bottom: 30px;
    font-size: $fs-sm;
  }

  a.cta {
    max-width: none;
    width: 100%;

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      max-width: 150px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      max-width: none;
      margin-left: 0;
      display: inline-block;
    }
  }

}

//end article.blog




















