
.owl-theme {

  div.owl-dots {

    margin-top: 15px;

    .owl-dot {

      span {
        background: $c-primary;
        @include opacity(50, .5);
      }

      &.active, &:hover {
        span {
          background: $c-primary;
          @include opacity(100, 1);
        }
      }

    }
  }


  div.owl-nav {

    margin: 0;
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    @include prefix(transform, translateY(-50%));

    button.owl-prev, button.owl-next {

      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      @include prefix(transform, translateY(-50%));
      @include opacity(50, 0.5);
      background: transparent!important;
      @include transition(opacity $trans);

      &:hover {
        @include opacity(100, 1);
      }

      i {
        width: 60px;
        height: auto;
        font-size: 45px;
        display: block;
        color: $c-primary;

        @media(max-width: 767px) {
          font-size: 30px;
          width: 40px;
        }
      }


    }

    button.owl-prev {
      left: 15px;

      @media(max-width: 991px) {
        left: 0;
      }
    }

    button.owl-next {
      right: 15px;

      @media(max-width: 991px) {
        right: 0;
      }
    }

  }//end .owl-nav















}

