span.contact-details {

  display: block;
  text-align: inherit;


  & > span {

    &:not(.social-list) {
      display: block;

      &, span {
        & + span.business-name {
          margin-top: 15px;

          strong {
            font-size: $h5-fs;
            font-weight: 700;
            margin-bottom: 0;
            font-family: $ff-accent;
          }
        }
      }
    }

    &.social-list {
      margin-top: 15px;
      display: inline-block;
      vertical-align: middle;

      a {
        padding: 5px;
        color: $c-primary;
        @include transition(color $trans);
        font-size: 24px;

        &:hover, &:active, &:focus {
          color: $c-primary-hover;
        }
      }

      & + span.social-list {
        margin-left: 15px;
      }
    }

  }//end span



  span.icon-wrap {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;

    i {
      font-size: 24px;
      color: $c-primary;
      line-height: 0;
      display: inline-block;
    }
  }


}//end span.contact-details
