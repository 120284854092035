

input[type="text"], input[type="email"], input[type="tel"], textarea, input[type="search"] {
  margin: 0;
  padding: 8px;
  font-size: $base-fs;
  font-family: $ff-copy;
  color: $black!important;
  background: transparent;
  border: solid 1px rgba($c-primary, 0.5);
  @include transition(border-color $trans);
  outline: none!important;
  @include border-radius(0);

  &:hover, &:focus, &:active {
    border-color: $c-primary-hover;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $black;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    color: $black;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    color: $black;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: $black;
  }

  width: 100%;
}

input[type="submit"], button[type="submit"] {
  font-size: $base-fs !important;
  cursor: pointer;
  font-weight: normal!important;
  background: $c-primary;
  color: $white;
  border: none;
  box-shadow: none;
  outline: none;

  &:hover {
    background: $c-primary-hover;
  }
}




//If container is coloured and content is dark:
section.content-light {

  input[type="text"], input[type="email"], input[type="tel"], textarea, input[type="search"] {
    color: $white!important;
    border: solid 1px rgba($white, 0.5);

    &:hover, &:focus, &:active {
      border: solid 1px rgba($white, 1);
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $white;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: $white;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
    }

  }

  input[type="submit"], button[type="submit"] {
    background: $white;
    color: $c-primary;

    &:hover {
      color: $c-primary-hover;
    }
  }
}


section.content-dark {

  input[type="text"], input[type="email"], input[type="tel"], textarea, input[type="search"] {
    color: $black!important;
    border: solid 1px rgba($black, 0.5);

    &:hover, &:focus, &:active {
      border: solid 1px rgba($black, 1);
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $black;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: $black;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: $black;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: $black;
    }

  }

  input[type="submit"], button[type="submit"] {
    background: $black;
    color: $white;

    &:hover {
      background: $pblack;
    }
  }
}















