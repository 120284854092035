@keyframes rotate-x {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes reveal_left_to_right {
  0% {
    @include transform (translateX(-100%));
  }
  50% {
    @include transform (translateX(0));
  }
  100% {
    @include transform (translateX(100%));
  }
}

@keyframes reveal_top_to_bottom {
  0% {
    @include transform (translateY(-100%));
  }
  50% {
    @include transform (translateY(0));
  }
  100% {
    @include transform (translateY(100%));
  }
}

@keyframes color {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pop-in {
  0% {

  }
  100% {
    @include transform (scale(1.1));
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-9-14 15:41:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.15) translateY(-15px);
    transform: scale(1.15) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.15) translateY(-15px);
    transform: scale(1.15) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-9-24 12:44:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    @include opacity(0, 0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    @include opacity(100, 1);
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    @include opacity(0, 0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    @include opacity(100, 1);
  }
}






/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
































