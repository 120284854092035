
section.usps {

  padding: 15px 0 20px;
  background-color: $blue!important;

  ul.usps {

    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: middle;
      font-family: $ff-copy!important;
      font-size: $fs-sm;
      position: relative;

      @media(max-width: 767px) {
        width: 50%;
        text-align: left;
        padding-left: 15px;

        &:nth-child(odd) {
          padding-right: 4px;
        }
      }

      span.icon {

        margin-right: 5px;

        @media(max-width: 767px) {
          position: absolute;
          left: 0;
          margin: 0;
        }

        i {
          color: $c-primary;
        }
      }

      & + li {
        margin-left: 15px;

        @media(max-width: 767px) {
          margin-left: 0;
        }
      }
    }

  }//end ul.usps


  &.bg-colored {
    ul.usps {
      li {
        color: $white;

        span.icon i {
          color: $white;
        }
      }
    }
  }


}//end section.usps
