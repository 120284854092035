@import "page-template-sections/require-sections";

body.theme-page-template {
  /*section:not(.hero):not(.bg-colored) {
    background-color: $white;
    &:nth-child(odd) {
      background-color: $off-white;
    }
  }*/

  section:not(.hero) {

    &:not(.section-wysiwyg) {
      @include section-headings;
    }

    &.content-light:not(.section-accordion) {
      *:not(.cta) {
        color: $white;
      }


      ol {

        li {
          &:before {
            color: $white;
          }
        }


        li > ol {

          li {

            &:before {
              color: $white;
            }
          }
        }

      }//end ol


      ul {
        li {
          &:before {
            color: $white;
          }
        }

        //end li
      }//end ul





      p, li {
        a:not(.cta) {
          color: $white;
          text-decoration-color: $white;

          &:hover {
            text-decoration-color: $white;
          }
        }
      }

      hr {
        border-top-color: rgba($white, 0.5);
      }

      blockquote:before {
        color: $white;
      }

    }

    &.bg-colored {
      background: $c-primary;

      a.cta.button-sc {
        outline: solid 1px $white;
      }


      &.content-dark {
        a.cta.button-sc {
          outline: solid 1px $black;
          color: $black;
        }
      }
    }

  }


  h2.has-subheading {
    display: inline-block;
    border-bottom: solid 2px $c-primary;
    padding-bottom: 15px;
    margin-bottom: 20px;

    & + p {
      font-size: 21px;
      margin-bottom: 30px;
    }
  }

  section.bg-colored h2.has-subheading {
    border-color: $white;
  }


  section.bg-colored.content-dark h2.has-subheading {
    border-color: $black;
  }

}


