
$white: #fff;
$off-white: #F8F8F8;

$black: #252b2d;
$pblack: #000;

$grey: rgba($pblack, 0.25);
$dark-grey: rgba($pblack, 0.65);

$gold: #c39a2c;


	//- Primary: #255d9e
  //- Light: #eef4fb
	//- Med-dark: #1c4576
	//- Dark: #122e4f
	//- Blue / black: #091727

$blue: #1c4576;
$off-white: #eef4fb;
$light-blue: #2b3c64;
$mid_blue: #1a729b;
$black: #091727;
$beige: #e2ded8;
$nav_bar_blue: #3e6b93;
//$blue: #255d9e;







$c-primary: $gold;
$c-primary-hover: darken($gold, 15);
