

footer#footer {

  background: $light-blue;
  padding: 60px 0 0;

  @media(max-width: 767px) {
    padding: 45px 0 0;
  }

  div.widget * {
    color: $off-white;
  }


  div.widget-inner {
    display: inline-block;
    width: auto;
  }//end widget inner


  div.widget h4 {
    text-transform: uppercase;
    font-family: $ff-accent;
    margin-bottom: 30px;
  }//div.widget h4 {


  div.widget {
    text-align: left;

    @media(max-width: 767px) {
      text-align: center;
    }

    & > div.widget-inner {
      text-align: left;

      @media(max-width: 767px) {
        text-align: center;
        padding: 15px 0;
        border-top: solid 1px rgba($off-white, 0.15);
        border-bottom: solid 1px rgba($off-white, 0.15);
      }
    }


    @media(max-width: 767px) {
      order: 2;
      margin-top: 15px;
    }
  }


  div.widget + div.widget {
    text-align: center;

    //@media(min-width: 768px) {
    //  display: flex;
    //  justify-content: center;
    //  align-items: flex-end;
    //}

    div.widget-inner {
      text-align: center;

      @media(max-width: 767px) {
        border: none;
      }
    }

    @media(max-width: 767px) {
      order: 1;
      margin-top: 0;
    }
  }


  div.widget + div.widget + div.widget  {
    text-align: right;
    //align-self: flex-end;

    @media(min-width: 768px) {
      display: block;
    }

    div.widget-inner {
      text-align: left;

      @media(max-width: 991px) {
        border: none;
      }
    }

    @media(max-width: 767px) {
      order: 3;
      margin-top: 15px;
      text-align: center;
    }
  }


  ul.footer-list {

    padding: 0;
    margin: 0;
    display: block;
    list-style: none;

    li {
      font-size: $fs-sm;

      & + li:not(.social-list) {
        margin-top: 5px;
      }
    }


    span.icon-wrap {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;

      i {
        font-size: 24px;
        color: $off-white!important;
        line-height: 0;
        display: inline-block;
      }
    }


    li.social-list {
      display: inline-block;
      vertical-align: top;
      margin-top: 15px;

      a {
        padding: 5px;
        color: $off-white;
        @include transition(color $trans);
        font-size: 24px;

        i {
          color: $off-white!important;
        }

        &:hover, &:active {
          color: $blue;
        }
      }

      & + li {
        margin-left: 15px;
      }
    }


    li:not(.social-list) {

      a {
        position: relative;
        text-decoration: underline;
        text-decoration-color: rgba($off-white, 0.0001);
        @include transition(all $trans);

        &:hover, &:active {
          text-decoration-color: $off-white;
        }
      }

    }//not social list




  }//end ul.footer-list


  nav.footer-nav {
    ul li {
      span.icon-wrap i {
        font-size: $base-fs;
        position: relative;
        left: -2px;
        @include transition(left $trans);
      }

      &:hover {
        span.icon-wrap i {
          left: 0;
        }
      }

    }
  }


  div.location-col {

    .img-wrap img {
      max-width: 120px;
      margin: 0 auto 30px;
    }

    address {
      font-size: $base-fs;
      margin-bottom: 0;
    }


    li.business-name {
      font-size: $base-fs;
      font-family: $ff-accent;
    }

  }//end .location-col


  div.footer-legal {
    background: $nav_bar_blue;
    margin-top: 45px;
    padding: 10px 0;

    * {
      color: rgba($white, 0.85);
      font-size: $fs-xs;
    }


    a {
      text-decoration: underline;
    }


    a.footer-links {
      display: inline-block;
      vertical-align: middle;

      & + a.footer-links {
        margin-left: 4px;
        padding-left: 4px;
        position: relative;

        &:before {
          content: ' | ';
          position: absolute;
          left: -2px;
        }
      }
    }

    div.col-md-4 {
      @media(max-width: 767px) {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

  }//end footer-legal



  //Slightly different arrangement if footer container is boxed:
  & > div.container {

    div.widget {
      text-align: left;

      @media(max-width: 767px) {
        text-align: center;
      }

      & + div.widget {
        text-align: center;

        & + div.widget {
          text-align: right;

          @media(max-width: 767px) {
            text-align: center;
          }
        }
      }
    }
  }//end !boxed


  //Widget padding for


  //Footer logo top of widgets:
  div.theme-footer-logo-top {
    img {
      max-width: 120px;
      margin: 0 auto 30px;
    }
  }










}//end footer#footer





























