/*************************************************************************************/
/*                                                                        mixins.scss*/
/*************************************************************************************/

@mixin prefix($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

//TRANSITION
@mixin transition($values) {
  -webkit-transition: $values;
  -moz-transition: $values;
  -o-transition: $values;
  transition: $values;
}

//X-BROWSER OPACITY:
@mixin opacity($value-ie, $value){
  filter: alpha(opacity=$value-ie);
  -moz-opacity: $value;
  -khtml-opacity: $value;
  opacity: $value;
}



@mixin hover-grow () {
  /* Grow */
  vertical-align: middle;
  transform: translateZ(0);
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.5s;
  transition-property: transform;

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.025);
  }
}


@mixin box-shadow-default () {
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 12px rgba(0,0,0,.12);
  -moz-box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 12px rgba(0,0,0,.12);
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 12px rgba(0,0,0,.12);
}



@mixin button-ripple () {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  display: inline-block;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .3s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
  }
}


@mixin box-shadow-hover () {
  -moz-box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1), 0 4px 9px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1), 0 4px 9px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.23);
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1), 0 4px 9px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.23);
}


//-------------------- Transform, Translate3d, TranslateX, TranslateY, Rotate --------------------//
@mixin transform($params) {
    -webkit-transform: $params;
    -moz-transform: $params;
    -ms-transform: $params;
    -o-transform: $params;
    transform: $params;
}


@mixin content-card () {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  background: $white;

  div.img-wrap {

    display: block;
    position: relative;

    a {
      display: block;
      position: relative;

      &:after {
        content: '';
        position: relative;
        display: block;
        padding-bottom: 75%;

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          padding-bottom: 50%;
        }

        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
          padding-bottom: 75%;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

  }//end .img-wrap


  div.content-wrap {
    padding: 30px 15px;

    h3 {
      font-size: $h4-fs;
      font-weight: 700;
      font-family: $ff-accent;
    }
  }


  div.cta-wrap {

    margin-top: auto;
    padding: 0 15px;

    a.cta {
      display: block;
      width: 100%;
      padding: 8px 15px;
      margin: 0 auto;
      min-width: 0;
    }

  }//end cta wrap


  //Adjust for 4 cols
  &.grid-items-4 {

    div.img-wrap {

      a:after {
        @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          padding-bottom: 75%;
        }

        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        	padding-bottom: 75%;
        }

        @media(min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          padding-bottom: 50%;
        }
      }

    }//div.img-wrap {

  }//end grid-items-4




}


@mixin section-headings () {
  h2 {
    & + p {
      margin-bottom: 30px;
    }
  }
}
