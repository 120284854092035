

section.background-section {

  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  padding: 90px 0;
  position: relative;
  min-height: 65vh;

  display: flex;
  align-items: center;


  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    min-height: 50vh;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    min-height: 65vh;
  }


  div.content-wrap {
    max-width: 1140px;
    margin: 0 auto;
  }

  & > div {
    position: relative;
    z-index: 5;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &.light {
    &:after {
      background: rgba($white, 0.45);
    }
  }


  &.dark {
    &:after {
      background: rgba($pblack, 0.45);
    }
  }


  &.coloured {
    &:after {
      background: rgba($c-primary, 0.65);
    }
  }


  //Content colours:
  &.content-light {
    h2, p {
      color: $white;
    }
  }

  &.content-dark {
    h2, p {
      color: $black;
    }
  }















}//end section.background-section


html.touchevents {
  section.background-section {
    background-attachment: scroll;
  }
}
