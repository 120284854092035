
section.hero {

  padding: 0;
  position: relative;


  div.slide-inner {

    padding: 60px 0;

    @media(max-width: 991px) {
      padding: 60px 30px;
    }
  }


  .slide-heading {
    font-size: $h2-fs;
    text-transform: uppercase;
    display: block;
    width: auto;
    margin-bottom: 40px;

    &.has-subheading {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: solid 1px $black;

      & + .slide-subheading {
        display: block;
      }
    }

    @media(max-width: 991px) {
      font-size: $h3-fs;
    }
  }


  .slide-subheading {
    font-size: $h4-fs;
    line-height: 1.4;
    font-family: $ff-copy;
    margin-bottom: 0;
    font-weight: 700;
    display: inline-block;

    @media(max-width: 991px) {
      font-size: $h5-fs;
    }
  }//end .slide-subheading {


  p.slide-para {
    margin: 30px auto 0;
  }


  a.cta {
    margin-top: 30px;

    @media(max-width: 767px) {
      padding: 8px 30px;
      margin-bottom: 30px;
    }
  }


  div.hero-slider {

    div.slide-item  {
      min-height: 85vh;
      display: flex;
      align-items: center;
      position: relative;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      &.overlay-light {
        &:after {
          background: rgba($white, 0.45);
        }
      }


      &.overlay-dark {
        &:after {
          background: rgba($pblack, 0.45);
        }
      }


      &.overlay-coloured {
        &:after {
          background: rgba($c-primary, 0.45);
        }
      }

      & > div {
        position: relative;
        z-index: 5;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

  }//end hero-slider


  &.content-color-light {
    h1, h2, h3, p {
      color: $white;
    }

    .slide-heading {
      border-color: rgba($white, 0.15);
    }

    .owl-theme div.owl-nav button.owl-next i, .owl-theme div.owl-nav button.owl-prev i {
      color: $white;
    }

    div.icon-down-outer span.icon-down {
      color: $white;
    }
  }


  &.content-color-dark {
    .owl-theme div.owl-nav button.owl-next i, .owl-theme div.owl-nav button.owl-prev i {
      color: $pblack;
    }

    div.icon-down-outer span.icon-down {
      color: $pblack;
    }
  }//end &.content-color-dark


  div.icon-down-outer {

    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 100;
    text-align: center;

    span.icon-down {


      color: $white;
      font-size: 42px;
      line-height: 1;

      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);

      -webkit-animation-name: hvr-pulse;
      animation-name: hvr-pulse;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

  } //end div.icon-down-outer


}//end section.hero


//Video hero:
section.hero.video-hero {


  div.hero-video {

    height: 100%;

    div.slide-item {
      min-height: 85vh;
      position: relative;
      z-index: 5;
      display: flex;
      align-items: center;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      &.overlay-light {
        &:after {
          background: rgba($white, 0.45);
        }
      }


      &.overlay-dark {
        &:after {
          background: rgba($pblack, 0.45);
        }
      }


      &.overlay-coloured {
        &:after {
          background: rgba($c-primary, 0.45);
        }
      }

      & > div {
        position: relative;
        z-index: 5;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

  } //end hero-slider

  div#theme-video-container {
    position: absolute!important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

//Adjust hero height based on header layout styles:
body {

  //Contact bar
  &.contact-bar {
    section.hero div.hero-slider, section.hero div.hero-video {
      div.slide-item  {
        min-height: 80vh;
      }
    }
  }

  &.header-logo-top {
    section.hero div.hero-slider, section.hero div.hero-video {
      div.slide-item  {
        min-height: 80vh;
      }
    }
  }

  &.header-logo-top.contact-bar {
    section.hero div.hero-slider, section.hero div.hero-video {
      div.slide-item  {
        min-height: 70vh;
      }
    }
  }

}//end body


//specific styles for the FIRST slide on the HOMEPAGE
body.home {


  section.hero {

    div.hero-slider {

      div.first-slide {

        height: 80vh;

        &:after {
          display: none;
        }

        div, .slide-heading {
          flex: 1;
          height: 100%;
        }

        .slide-heading  {
          margin: 0!important;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          span {
            display: block;
            position: absolute;
            top: 30px;
            font-size: $h3-fs;
            font-weight: 700;
            letter-spacing: 25px;
            padding-left: 25px;

            & + span {
              top: initial;
              bottom: 30px;
            }
          }
        }

      }

    }//end hero-slider

  }//end section.hero {


}//end body.home {





























