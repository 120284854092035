

//Cart nav item:
ul[id^=menu-main-menu]>li.wc-cart-nav-item {

  align-self: center;

  a {
    padding: 5px;
    position: relative;
    color: $c-primary;
    @include transition(color $trans);

    &:hover, &:focus, &:active {
      color: $c-primary-hover;
    }

    &:before {
      display: none;
    }
  }


  span.icon-wrap {
    font-size: 21px;
  }


  span.cart-count {
    position: relative;
    font-size: $fs-sm;
    top: 5px;
  }



}//end ul[id^=menu-main-menu]>li.wc-cart-nav-item {


//Base styles for WooCommerce
body.woocommerce, body.woocommerce-cart div.autonomy-woocommerce {

  nav.woocommerce-breadcrumb {
    display: block;
    margin: 15px 0;
    float: none;

    color: $black;

    a {
      color: $black;
      font-weight: bold;
      @include transition(all $trans);
      text-decoration: underline;
      text-decoration-color: transparent;

      &:hover, &:focus, &:active {
        text-decoration-color: $black;
      }
    }
  }


  .woocommerce-error, .woocommerce-info, .woocommerce-message {
    margin: 30px 0 40px;

    a.button.wc-forward:not(.checkout-button), a.button.wc-backward {
      @media(max-width: 767px) {
        margin-left: 5px;
      }
    }
  }


  a.button.wc-forward:not(.checkout-button), a.button.wc-backward {
    @extend .cta;
    padding: 10px 15px;
    width: auto;
    min-width: 0;
    @include border-radius(0);
    font-size: $fs-sm!important;
  }


  header.woocommerce-products-header {
    display: block;
    float: none;
    margin-bottom: 30px;

    h1 {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }


  .woocommerce-result-count {
    float: none;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
  }


  .woocommerce-ordering {
    float: none;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    text-align: right;
    margin-left: -4px;

    select.orderby {
      padding: 5px;
      @include border-radius(0);
    }
  }


  @media(max-width: 767px) {
    .woocommerce-result-count, .woocommerce-ordering {
      display: block;
      width: 100%;
      text-align: left;
      margin-left: 0;
    }

    .woocommerce-ordering {
      margin-bottom: 30px;
    }
  }


  div.product p.price, div.product span.price {
    display: block;
    color: $c-primary;
    font-size: $base-fs;
  }


  div.autonomy-products-row {
    padding-bottom: 30px;
  }//end autonomy-products-row


  div.autonomy-product-outer {

    margin-bottom: 30px;

    a:not(.button) {
      color: inherit;
      display: block;
      position: relative;
      padding: 15px 15px 90px;
      background: $white;
      border: solid 1px $grey;
      @include box-shadow-default;
      height: 100%;
    }


    img.attachment-woocommerce_thumbnail {
      margin: -15px -15px 0 -15px;
      display: block;
      position: relative;
      max-width: none;
      width: calc(100% + 30px);
      object-fit: cover;
      object-position: center;
      height: 240px;
    }


    h2.woocommerce-loop-product__title {
      font-size: $h4-fs;
      margin: 15px 0;
      text-align: center;
    }

    span.price {
      text-align: center;
    }


    a.button {
      @extend .cta;
      position: absolute;
      left: 50%;
      margin: 0 auto;
      width: auto;
      text-align: center;
      bottom: 20px;
      display: inline-block;
      transform: translateX(-50%);
      @include border-radius(0);
      min-width: 0;
      font-size: $fs-sm;
      padding: 10px 15px;

      //Hide the AJAX cart button
      &.ajax_add_to_cart {
        display: none;
      }
    }








  }//end autonomy-product-outer




}//end body.woocommerce div.autonomy-woocommerce



//Single Product:
body.woocommerce.single-product div.autonomy-woocommerce {

  h1.product_title.entry-title {
    font-size: $h2-fs;
    line-height: 1;

    @media(min-width: 992px) {
      margin-top: -8px;
    }

    @media(max-width: 767px) {
      font-size: $h3-fs;
    }
  }


  h2:not(.woocommerce-loop-product__title) {
    font-size: $h3-fs;

    @media(max-width: 767px) {
      font-size: $h4-fs;
    }
  }


  div.product {


    @media(min-width: 992px) {

      .woocommerce-product-gallery, div.summary.entry-summary {
        float: none;
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }

      .woocommerce-product-gallery {
        margin-right: -4px;
        padding-right: 15px;
      }

      div.summary.entry-summary {
        padding-left: 15px;
      }

    }


    div.summary.entry-summary {
      @media(max-width: 768px) {
        margin-top: 30px;
      }
    }


    div.images, div.summary.entry-summary {
      margin-bottom: 0;
    }

    p.price {
      margin: 15px 0;
      font-size: $h5-fs;
      font-weight: 700;
    }


    .woocommerce-product-details__short-description {
      margin: 30px 0;
    }


    form.cart {

      div.quantity {
        display: block;
        float: none;
      }

      p#wc-stripe-payment-request-button-separator {
        margin-top: 30px;
      }

      div#wc-stripe-payment-request-wrapper {
        padding: 0!important;
        margin-top: 30px!important;
        display: block;
      }

      button[type="submit"].button {
        @extend .cta;
        @include border-radius(0);
        width: 100%;
        min-width: 0;
        padding: 15px 30px;
        margin: 30px 0 0;
        display: block;
        float: none;
      }

    }//end form.cart


    div.product_meta {
      display: none;
    }


    /*Gallery*/
    ol.flex-control-nav.flex-control-thumbs {
      display: flex;

      li {
        float: none;
        display: inline-block;
      }
    }

    figure.woocommerce-product-gallery__wrapper img {
      height: 350px!important;
      object-fit: cover;
      object-position: center;

      @media(max-width: 767px) {
        height: 240px!important;
      }
    }

  }//end product



  div.woocommerce-tabs.wc-tabs-wrapper {

    margin-top: 45px;
    margin-bottom: 45px;
    padding-top: 45px;
    border-top: 1px solid $grey;

    ul.tabs.wc-tabs {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        @include border-radius(0);
      }
    }

    div.woocommerce-Tabs-panel {
      margin: 0;
      border: solid 1px #d3ced2;
      border-top: none;
      padding: 30px;

      @media(max-width: 767px) {
        padding: 30px 15px;
      }
    }



    div#tab-reviews {


      div#comments {
        margin-bottom: 15px;
      }


      span#reply-title, p.comment-notes {
        font-size: $fs-sm;
      }

      label {
        font-size: $fs-sm;
        font-weight: 700;
        text-transform: uppercase;
      }


      .comment-form-rating {
        margin: 20px 0 15px;
      }


      p.stars a {
        color: $gold;
      }


      p.form-submit input#submit {
        @extend .cta;
        padding: 10px 15px;
        width: auto;
        min-width: 0;
        margin-top: 15px;
        @include border-radius(0);
        font-size: $fs-sm!important;
      }

    }//end div#tab-reviews








  }//end .woocommerce-tabs.wc-tabs-wrapper


  section.related.products {
    padding-top: 35px;
    border-top: 1px solid $grey;
  }


}//end body.woocommerce.single-product div.autonomy-woocommerce




//Cart
body.woocommerce-cart div.autonomy-woocommerce {

  h1 {
    margin-bottom: 30px;
  }

  h2 {
    font-size: $h3-fs;
  }


  .woocommerce-error, .woocommerce-info, .woocommerce-message {
    background: $white;
  }


  table.shop_table.cart {

    background: $white;
    @include border-radius(0);

    img {
      width: 150px;
    }

    input#coupon_code {
      width: 150px!important;
    }


    td.product-name a {
      color: $black;
      text-decoration: underline;
      text-decoration-color: transparent;
      @include transition(all $trans);

      &:hover, &:active, &:focus {
        text-decoration-color: $black;
      }
    }

    span.wc-qty-label {
      display: none;
    }


    td.actions {
      background: $white!important;
    }


    div.coupon {
      button.button {
        @extend .cta;
        padding: 10px 15px;
        width: auto;
        min-width: 0;
        @include border-radius(0);
      }
    }

    button[name="update_cart"].button {
      @extend .cta;
      padding: 10px 15px;
      width: auto;
      min-width: 0;
      @include border-radius(0);

      @media(max-width: 768px) {
        display: block;
        margin-top: 30px;
        width: 100%;
      }
    }


    tr:nth-child(2n) td {
      background-color: #efefef;
    }










  }//end table.shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents {

  p#wc-stripe-payment-request-button-separator {
    margin: 30px 0;
  }

  a.checkout-button {
    @extend .cta;
    @include border-radius(0);
    width: 100%;
    min-width: 0;
    padding: 15px 30px;
    margin: 0 auto 0;
    display: block;
    float: none;
    font-size: $base-fs;
    font-weight: 400;
  }


  table.shop_table.shop_table_responsive:not(.cart) {
    @include border-radius(0);
    background: $white;

    a.shipping-calculator-button {
      color: $black;
      text-decoration: underline;
      text-decoration-color: transparent;
      @include transition(all $trans);

      &:hover, &:active, &:focus {
        text-decoration-color: $black;
      }
    }


    tr.woocommerce-shipping-totals.shipping > td {
      background: $white!important;
    }


    section.shipping-calculator-form {
      padding: 15px 0;

      p.form-row > span {
        display: block;
        width: 100%;
      }

      .select2-container {

        line-height: normal;
        text-align: left;

        .select2-selection--single {
          @include border-radius(0);
          border-color: $grey;

          height: auto;
          margin: 0;

          span.select2-selection__rendered {
            line-height: normal;
            height: auto;
            padding: 8px 20px 8px 8px;
          }

          .select2-selection__arrow {
            height: auto;
            top: 50%;
            right: 5px;
          }
        }

      }

      button[name="calc_shipping"].button {
        @extend .cta;
        padding: 10px 15px;
        width: auto;
        min-width: 0;
        @include border-radius(0);
      }

    }

  }//end table NOT cart


  .cart-collaterals {
    margin-top: 45px;
  }


}//end body.woocommerce.woocommerce-cart div.autonomy-woocommerce




//Checkout:
body.woocommerce-checkout div.autonomy-woocommerce {

  h3 {
    font-size: $h4-fs;
    margin-bottom: 30px;
  }


  .form-row.place-order {
    margin-bottom: 0;
  }


  .wc-checkout-inner {
    background: $white;
    padding: 30px;
    border: solid 1px #e3e3e3;

    @media(max-width: 991px) {
      padding: 30px 15px;
    }

    @media(max-width: 767px) {
      padding-bottom: 0;
    }
  }


  div#customer_details {
    @media(max-width: 767px) {
      padding-bottom: 30px;
    }
  }//end customer_details


  div.checkout-col-left {

    position: relative;
    @media(min-width: 992px) {

      padding-right: 15px;

      &:after {
        content: '';
        position: absolute;
        right: -15px;
        top: 0;
        width: 1px;
        height: 100%;
        background: #e3e3e3;
      }
    }

  }//end checkout-col-left


  div.checkout-col-right {
    @media(min-width: 992px) {
      padding-left: 15px;
    }


    label.woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox {
      margin: 0;
    }
  }//end div.checkout-col-right


  div.order-review-row {
    margin-top: 30px;
  }

  .woocommerce-form-coupon-toggle {
    margin: 30px 0;
  }

  .woocommerce-info {
    background: $white;
    border-top-color: $c-primary;

    &:before {
      color: $c-primary;
    }

    a.showcoupon {
      color: $black;
      font-weight: 700;
      text-decoration: underline;
      text-decoration-color: transparent;
      @include transition(all $trans);

      &:hover, &:active, &:focus {
        text-decoration-color: $black;
      }
    }
  }


  form.checkout_coupon.woocommerce-form-coupon {
    margin-top: -15px!important;
    @include border-radius(0);
    background: $white;

    & > p:not(.form-row) {
      font-size: $fs-sm;
      margin-bottom: 5px;
    }

    p.form-row {
      padding: 0;
      margin-bottom: 0;
    }


    button[name="apply_coupon"] {
      @extend .cta;
      padding: 10px 15px;
      width: auto;
      min-width: 0;
      @include border-radius(0);
    }




  }//end coupon form

  p#wc-stripe-payment-request-button-separator {
    margin: 30px 0!important;
  }


  //Form fields
  .woocommerce-billing-fields__field-wrapper, .woocommerce-shipping-fields, .woocommerce-additional-fields {

    p.form-row {

      padding: 0;
      margin-bottom: 30px;

      label {
        font-size: $fs-sm;
        margin-bottom: 5px;
        line-height: 1;
        color: $dark-grey;
      }

      span.woocommerce-input-wrapper {
        display: block;
        width: 100%;
      }


      input, textarea, select {
        border-color: $grey;
      }


    }//end form-row



    //Select 2 styles:
    .form-row .select2-container {

      line-height: normal;

      .select2-selection--single {
        @include border-radius(0);
        border-color: $grey;

        height: auto;
        margin: 0;

        span.select2-selection__rendered {
          line-height: normal;
          height: auto;
          padding: 8px 20px 8px 8px;
        }

        .select2-selection__arrow {
          height: auto;
          top: 50%;
          right: 5px;
        }
      }

    }//end .form-row .select2-container {






  }//end .woocommerce-billing-fields__field-wrapper


  table.shop_table.woocommerce-checkout-review-order-table {
    table-layout: fixed;
    @include border-radius(0);
  }


  //Payment wrapper
  div#payment {

    @media(max-width: 767px) {
      margin: 0 -15px;
    }

    @include border-radius(0);

    label {
      display: block;
      margin-bottom: 5px;
      line-height: 1;
      float: none;
    }


    div.stripe-card-group {
      display: block;
      position: relative;
      width: 100%;
    }


    div.wc-stripe-elements-field {

      margin: 0;
      padding: 8px;
      width: 100%;

    }//end wc-stripe-elements-field

    div.form-row {

      &.form-row-first, &.form-row-last {
        display: inline-block;
        float: none;
        vertical-align: top;
        width: 50%;
      }

      &.form-row-last {
        margin-left: -4px!important;
      }

    }


    button#place_order {
      @extend .cta;
      padding: 15px;
      @include border-radius(0);
      margin-bottom: 0;

      @media(max-width: 767px) {
        max-width: none;
        width: 100%;
      }
    }

    .payment_box.payment_method_stripe {
      @media(max-width: 767px) {
        padding: 0;
      }
    }

  }//end div#payment


  p#order_comments_field {
    margin-bottom: 0;
  }























}//end body.woocommerce-checkout div.autonomy-woocommerce




//Order confirmation:
body.woocommerce-order-received div.autonomy-woocommerce {

  h1 {
    margin-bottom: 30px;
  }

  h2 {
    font-size: $h3-fs;
  }

  div.woocommerce-order {

    p.woocommerce-notice.woocommerce-notice--success.woocommerce-thankyou-order-received {
      margin-bottom: 30px;
    }


    ul.woocommerce-order-overview.woocommerce-thankyou-order-details.order_details {
      padding: 0;
      margin: 30px 0;

      @media(max-width: 767px) {
        li  {
          float: none;
          display: block;
          border: none!important;

          & + li {
            margin-top: 15px;
          }
        }
      }
    }


    section.woocommerce-order-details {
      padding: 30px;
      background: $white!important;
      border: solid 1px #e3e3e3;

      @media(max-width: 767px) {
        padding: 30px 15px;
      }

      table.woocommerce-table.woocommerce-table--order-details.shop_table.order_details {
        @include border-radius(0);
      }
    }


    td.woocommerce-table__product-name.product-name a {
      color: $black;
      text-decoration: underline;
      text-decoration-color: transparent;
      @include transition(all $trans);

      &:hover, &:active, &:focus {
        text-decoration-color: $black;
      }
    }




  }//end div.woocommerce-order


























}//end body.woocommerce-order-received div.autonomy-woocommerce {














































