


#posts-pagination {

  text-align: left;
  padding: 0;
  margin: 15px 0 45px;

  @media(max-width: 991px) {
    text-align: center;
  }

  & > div {
    display: inline-block;
    vertical-align: top;
  }


  a {
    background: $white;
    padding: 8px 8px;
    border: solid 1px $grey;

    font-size: $fs-xs;
    text-transform: uppercase;
    color: $black;

    display: block;

    @include transition(all .3s ease-in-out);

    span.meta-nav {
      font-size: 24px;
      position: relative;
      top: 2px;
      line-height: 0;
    }

    span.meta-nav {
      position: relative;
    }

    &:hover {
      color: $white;
      background: $black;
      border-color: $black;
    }
  }


  div.previous + div.next {
    margin-left: 8px;
  }

}
