

div.header-contact-bar {

  background: $c-primary;

  @media(max-width: 991px) {
    margin-bottom: 8px;
  }

  ul {

    padding: 0;
    margin: 0;
    list-style: none;

    li {

      display: inline-block;
      vertical-align: middle;
      @include transition(opacity $trans);
      @include opacity(85, 0.85);

      &:hover {
        @include opacity(100, 1);
      }

      & + li {
        margin-left: 15px;
      }


      a {
        color: $white;
        text-decoration: none;
        font-size: $fs-sm;

        &:active {
          @include opacity(100, 1);
        }
      }


      span.icon-wrap {

        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        i {
          color: $white;
        }


      }//span.icon-wrap

      i {
        font-size: 21px;
      }


      span.contact-method-wrap {

        @media(max-width: 767px) {
          display: none;
        }

      }//end span.contact-method-wrap


    }//end li

  }//end ul.contact-bar-items


  ul.contact-bar-items {

    @media(max-width: 991px) {
      display: flex;
      align-items: center;

      li.social-list-outer {
        margin-left: auto!important;

        ul li + li {
          margin-left: 15px;
        }
      }
    }


    li.social-list {

      & + li.social-list {
        margin: 5px;
      }

      &:last-child {
        margin-right: 0!important;
      }

      a {
        padding: 8px;
      }
    }

  }//end ul.contact-bar-items


}//end .header-contact-bar
