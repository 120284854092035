

section{
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  margin: 0;
  padding: 60px 0;

  @media(max-width: 767px) {
    padding: 45px 0;
  }

  &.bg-transparent {
    background-color: $white!important;
  }

  &.bg-beige {
    background-color: $beige!important;
    padding-top: 60px!important;
    @media(max-width: 767px) {
      padding-top: 45px!important;
    }
  }

  &.bg-blue {
    background-color: $mid_blue!important;
    padding-top: 60px!important;
    @media(max-width: 767px) {
      padding-top: 45px!important;
    }
  }

  &.bg-pale_blue {
    background-color: $off-white!important;
    padding-top: 60px!important;
    @media(max-width: 767px) {
      padding-top: 45px!important;
    }
  }

  &.bg-colored {
    background-color: $mid_blue!important;
    padding-top: 60px!important;
    @media(max-width: 767px) {
      padding-top: 45px!important;
    }
  }

  &.bg-waves {
    background-color: $white!important;
    background-image: url(/wp-content/themes/Autonomy_v1_1_4/dist/images/waves-solid-2400px.png);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: repeat;
    background-attachment: scroll;
  }

  &.bg-waves_semi_opaque {
    background-color: $white!important;
    background-image: url(/wp-content/themes/Autonomy_v1_1_4/dist/images/waves-semi-opaque-2400px.jpg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: repeat;
    background-attachment: scroll;
  }

}


html.touchevents {
  section {
    background-attachment: scroll;
  }
}
