

section.content-img-row {


  div.content-wrap {
    padding: 45px 0;

    @media(max-width: 991px) {
      padding-top: 0;
    }

    &.content-left {
      @media (min-width: 992px) {
        padding-right: 30px;
      }
    }


    &.content-right {
      @media (min-width: 992px) {
        padding-left: 30px;
      }
    }


  }//content-wrap


  div.img-wrap {

    height: 100%;
    position: relative;

    &:after {
      content: '';
      position: relative;
      display: block;
      padding-bottom: 75%;

      @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        padding-bottom: 50%;
      }

      @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        padding-bottom: 75%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      @include box-shadow-default;
      position: absolute;
      top: 0;
      left: 0;
    }

  }

}//end section.content-img-row
