
section.grid-section {

  padding-bottom: 30px;

  div.grid-item-outer {
    margin-bottom: 30px;
  }

  div.grid-item {

    @include content-card;

    -moz-box-shadow: 7px 7px 0px $light-blue, 7px 7px 0px $light-blue;
    -webkit-box-shadow: 7px 7px 0px $light-blue, 7px 7px 0px $light-blue;
    box-shadow: 7px 7px 0px $light-blue, 7px 7px 0px $light-blue;

    position: relative;
    padding-bottom: 0;

    div.img-wrap a img {
      position: relative;
      height: 350px;
      @include prefix(transform, perspective(1000px) translateZ(0));
      @include transition(all $trans);
    }

    div.img-wrap a {

      position: relative;
      display: block;
      overflow: hidden;

      &:after {
        display: none;
      }

      &:hover, &:focus {
        img {
          @include prefix(transform, perspective(1000px) translateZ(30px));
        }
      }
    }



    div.content-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: $off-white;
      padding: 0;
      text-align: center;

      a {

        display: block;
        padding: 15px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 90px;
          z-index: 2;
          background: -moz-linear-gradient(1deg, rgba($pblack, 0.5), transparent);
          background: -webkit-linear-gradient(1deg, rgba($pblack, 0.5), transparent);
          background: linear-gradient(1deg, rgba($pblack, 0.5), transparent);
        }

        & > * {
          color: $off-white;
          position: relative;
          z-index: 2;
        }
      }//end a

      h3 {
        font-weight: 500;
        margin: 0;
      }

    }

    div.cta-wrap {
      display: none;
    }

  }//end grid item

}//end section.grid-section
