
aside#blog-sidebar {

  background: $white;
  border: solid 1px $grey;
  padding: 15px;

  div.widget {

    margin-bottom: 0;

    & + div.widget {
      margin-top: 30px;
      border-top: solid 1px $grey;
      padding-top: 30px;
    }

    h4 {
      font-size: $p-fs;
      font-weight: bold;
    }

  }


  div.widget_search {

    text-align: right;


    label {
      width: 100%;
    }


    input[type="search"] {
      background: $off-white;
      border: solid 1px $grey;
      width: 100%;
      max-height: none;
      margin-bottom: 8px;
      outline: none!important;
      box-shadow: none!important;
      border-radius: none!important;
      text-align: left;
    }



    input[type="submit"] {
      width: 90px;
      background: $c-primary;
      color: $white;
      border: none !important;
      display: inline-block;
      vertical-align: middle;
      @include transition(background .3s ease-in-out);
      font-family: $ff-copy;
      padding: 2px 5px;
      max-width: none;
      font-size: $fs-sm;
      font-weight: lighter;

      &:hover {
        background: $c-primary-hover;
      }
    }
  }



  ul.custom-recent-posts {
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 15px;
      display: table;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a.custom-recent-post-tn {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 0 0;
      width: 80px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    a.custom-recent-post-content {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 90px);
    }


    small {
      font-weight: normal;
      color: $dark-grey;
      font-size: $fs-xs;
      display: block;
      margin-bottom: 5px;
      line-height: 1;
    }

    h5 {
      font-size: $fs-sm;
      color: $black;
      font-weight: 700;
      text-transform: uppercase;
    }

  }//end recent posts



  ul.custom-blog-categories {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      display: block;
      margin-bottom: 0;
      overflow: hidden;


      &:before {
        display: inline-block;
        font-family: Ionicons;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f125";
        color: $c-primary;
        width: auto;
        display: inline-block;
        vertical-align: middle;
        font-size: $fs-sm;
        margin-left: 0;
        @include transition(color .3s ease-in-out);
      }


      & + li {
        margin-top: 5px;
      }



      a {
        overflow: hidden;
        font-size: $fs-sm;
        display: inline-block;
        vertical-align: middle;
        color: $black;
        position: relative;
        margin-left: 8px;

        &:before, &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          @include transition(all .185s ease-in-out);
          bottom: 0;
        }

        &:before {
          left: -100%;
          background: $c-primary;
        }

        &:after {
          left: 0;
          background: $white;
        }

        &:hover {
          &:before {
            left: 0;
          }

          &:after {
            left: 100%;
          }
        }
      }//end a

    }//end li


  }//end blog cats



}//and sidebar
