

section.grid-section {



}//end section.grid-section


div.grid-item {

  div.img-wrap {
    img {
      width: 100%;
      height: auto;
    }
  }

}
