
body.single-post {

  background: $off-white;


  section#blog-post {
    padding-top: 30px;

    @media(max-width: 767px) {
      padding-top: 15px;
    }
  }


  div.single-blog-post {
    background: $white;
    border: solid 1px $grey;
    padding: 15px 15px 30px 15px;
  }


  header.blog-single-header {

    div.img-wrap {
      img {
        height: 350px;
        width: 100%;
        object-fit: cover;
        object-position: center;

        @media(max-width: 767px) {
          height: 210px;
        }
      }
    }

    h1 {
      font-size: $h2-fs;
      text-transform: none;
      font-weight: normal;
      margin-top: 45px;
      margin-bottom: 15px;

      @media(max-width: 767px) {
        font-size: $h3-fs;
        margin-top: 30px;
      }
    }

    div.blog-post-entry-meta {
      font-weight: normal;
      color: $black;


      ul.theme-post-meta {

        padding: 0;
        margin: 0 0 30px;
        padding-bottom: 25px;
        list-style: none;
        display: block;
        border-bottom: solid 1px rgba($grey, .5);;

        @media(max-width: 767px) {
          max-width: none;
        }

        li {
          display: inline-block;
          vertical-align: top;
          width: auto;
          font-size: $fs-sm;
          position: relative;

          & + li {
            margin-left: 15px;
          }

          &.post-author {
            @media(max-width: 767px) {
              margin-left: 0;
              display: block;
            }
          }

          i {
            vertical-align: middle;
            color: $c-primary;
            font-size: $fs-xs;
            position: relative;
            margin-right: 5px;
            line-height: 0;
            top: -1px;
          }


          &.post-category {
            padding-left: 15px;

            a {
              display: block;
            }

            i {
              position: absolute;
              top: 50%;
              left: 0;
              @include prefix(transform, translateY(-50%));
            }
          }
        }
      }

      a.bt-anchor {
        color: $black;
        vertical-align: middle;
        display: inline-block;
      }
    }
    //end entry meta

  }//end blog post header


  div.entry-content.expj-wysiwyg {

    h2 {
      font-size: $h3-fs;
    }


    h3 {
      font-size: $h4-fs;
    }

  }//end .entry-content.expj-wysiwyg



}//end single blog post



































