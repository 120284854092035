
section.section-accordion {


  div.accordion {
    @include box-shadow-default;
  }


  div.card {
    @include border-radius(0);
    border: none;
  }

  div.card-header {

    padding: 0;
    border: none;

    button {
      display: block;
      background: $blue!important;
      @include transition(all $trans);
      color: $white;
      font-family: $ff-accent;
      font-size: $h4-fs;
      outline: none !important;
      @include border-radius(0);
      width: 100%;
      text-align: left;
      position: relative;
      text-decoration: none !important;
      @include prefix(box-shadow, none);
      padding: 12px 45px 12px 15px;

      span.icons-wrap {
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        -moz-transform: translateY(-50%) rotate(0deg);
        -webkit-transform: translateY(-50%) rotate(0deg);
        transform: translateY(-50%) rotate(0deg);
        -moz-transform-origin: center;
        -webkit-transform-origin: center;
        transform-origin: center;
        font-size: 24px;
        @include transition(all $trans);
        margin-top: 2px;
      }

      &:not(.collapsed) {
        span.icons-wrap {
          -moz-transform: translateY(-50%) rotate(45deg);
          -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
        }
      }

      &:hover, &:active, &:focus, &:not(.collapsed) {
        background: $light-blue!important;
        color: $white;
        text-decoration: none !important;
        @include prefix(box-shadow, none);
      }
    }//end button
  }//end card header


  div.card-body {
    background: $off-white;
    padding: 30px;
    border: solid 1px $grey;
    border-top: none;
    border-bottom: none;
  }//end card-content-outer


  div.card:last-child {
    div.card-body {
      border-bottom: solid 1px $grey;
    }
  }


  &.bg-colored {

    div.section-headings {
      * {
        color: $white;
      }
    }

    div.card-header button {
      background: $white;
      color: $c-primary;

      &:hover, &:active, &:focus, &:not(.collapsed) {
        background: $c-primary-hover;
        color: $white;
      }
    }

    div.card-body {
      border: none;
    }//end card-content-outer

  }//end &.bg-colored {


}//end section.section-accordion {
