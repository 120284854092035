

section.section-wysiwyg {

  &.spacing-bottom {
    padding-top: 0;
  }

  &.spacing-top {
    padding-bottom: 0;
  }

}
